import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { LIST_PAGE_COUNT } from '../../data/common';
import { trim, map } from 'lodash';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import guestCardAPI from '../../middleware/guestCardAPI';
import GuestCardHistoryTable from '../../components/GuestCard/GuestCardHistoryTable';
import GuestCardHistoryDetail from '../../components/GuestCard/GuestCardHistoryDetail';

const GuestCardHistory = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [guestCardHistory, setGuestCardHistory] = useState();
  const [guestCardHistoryTotal, setGuestCardHistoryTotal] = useState();
  const [
    gusetCardHistoryTotalIncome,
    setGuestCardHistoryTotalIncome,
  ] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [search, setSearch] = useState({
    typeId: undefined,
    cardId: undefined,
    startAt: undefined,
    endAt: undefined,
  });
  const [historyDetail, setHistoryDetail] = useState();
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [guestCardTypes, setGuestCardTypes] = useState();

  useEffect(() => {
    guestCardAPI.getGuestCardTypes().then(
      (rs) => {
        setGuestCardTypes(rs);
      },
      (error) => {
        alertError(error);
      },
    );
  }, []);

  useEffect(() => {
    getGuestCardHistory();
  }, [pageNum]);

  const getGuestCardHistory = () => {
    setIsLoading(true);

    const startAt =
      trim(search.startAt) !== ''
        ? moment(search.startAt).format('YYYY-MM-DD')
        : '';

    const endAt =
      trim(search.endAt) !== ''
        ? moment(search.endAt).format('YYYY-MM-DD')
        : '';

    guestCardAPI
      .getGuestCardHistory(
        {
          ...search,
          startAt: startAt,
          endAt: endAt,
        },
        LIST_PAGE_COUNT,
        pageNum,
      )
      .then(
        (rs) => {
          const momentNow = moment();

          setGuestCardHistory(
            map(rs.historyList, (history) => {
              const momentSignInTime =
                trim(history.signInTime) !== ''
                  ? moment(history.signInTime)
                  : undefined;
              const momentSignOutTime =
                trim(history.signOutTime) !== ''
                  ? moment(history.signOutTime)
                  : undefined;

              const historyStatus = momentSignOutTime ? 'bill' : 'inUse';
              const useTimeSec =
                historyStatus === 'bill'
                  ? momentSignOutTime.diff(momentSignInTime, 'seconds')
                  : momentNow.diff(momentSignInTime, 'seconds');

              return {
                ...history,
                historyStatus: historyStatus,
                signInTime: momentSignInTime.format('YYYY-MM-DD HH:mm:ss'),
                signOutTime: momentSignOutTime
                  ? momentSignOutTime.format('YYYY-MM-DD HH:mm:ss')
                  : '',
                useTimeSec: useTimeSec,
              };
            }),
          );
          setGuestCardHistoryTotal(rs.totalCount);
          setGuestCardHistoryTotalIncome(rs.totalIncome);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleChangeSearch = (name, value) => {
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleSearch = () => {
    if (pageNum === 1) {
      getGuestCardHistory();
    } else {
      setPageNum(1);
    }
  };

  const openDetail = (history) => {
    setHistoryDetail(history);
    setIsOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setHistoryDetail(undefined);
    setIsOpenDetail(false);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              使用記錄
            </Typography>
          </Grid>
          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div className="bonus__list__totals">
              <div className="bonus__list__total bonus__list__total--reward">
                <div className="bonus__list__total-number">
                  {gusetCardHistoryTotalIncome
                    ? gusetCardHistoryTotalIncome
                    : 0}
                  <div className="bonus__list__total-title">收入</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <DatePicker
            autoOk
            label="開始時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={search.startAt || null}
            onChange={(e) => {
              handleChangeSearch('startAt', e);
            }}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <DatePicker
            autoOk
            label="結束時間"
            clearable
            disableFuture
            inputVariant="outlined"
            value={search.endAt || null}
            onChange={(e) => {
              handleChangeSearch('endAt', e);
            }}
            format="yyyy-MM-dd"
            style={{
              marginRight: '12px',
            }}
          />

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading || !guestCardTypes}>
            <InputLabel>卡片類型</InputLabel>
            <Select
              value={search.typeId || ''}
              onChange={(e) => {
                handleChangeSearch('typeId', e.target.value);
              }}
              label="卡片類型">
              <MenuItem value="">不限</MenuItem>

              {guestCardTypes &&
                map(guestCardTypes, (guestCardType) => {
                  return (
                    <MenuItem value={guestCardType.id} key={guestCardType.id}>
                      {guestCardType.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <TextField
            label="CardId"
            type="search"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.cardId || ''}
            onChange={(e) => {
              handleChangeSearch('cardId', e.target.value);
            }}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={handleSearch}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && (!guestCardHistory || guestCardHistory.length <= 0) && (
          <TableEmpty message="查無使用記錄" />
        )}

        {!isLoading && guestCardHistory && guestCardHistory.length > 0 && (
          <GuestCardHistoryTable
            guestCardHistory={guestCardHistory}
            total={guestCardHistoryTotal}
            openDetail={openDetail}
            pageNum={pageNum}
            setPageNum={setPageNum}
          />
        )}
      </Box>

      <Drawer anchor="right" open={isOpenDetail} onClose={handleCloseDetail}>
        <GuestCardHistoryDetail
          history={historyDetail}
          closeDrawer={handleCloseDetail}
        />
      </Drawer>
    </div>
  );
};

export default GuestCardHistory;
