import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Page404 from '../../components/Page404';
import GuestCardTypeList from './GuestCardTypeList';
import GuestCardList from './GuestCardList';
import GuestCardHistory from './GuestCardHistory';

const GuestCardRoute = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`/GuestCards/GuestCardTypeList`}
        component={GuestCardTypeList}
      />
      <Route
        exact
        path={`/GuestCards/GuestCardList`}
        component={GuestCardList}
      />
      <Route
        exact
        path={`/GuestCards/GuestCardHistory`}
        component={GuestCardHistory}
      />
      <Route component={Page404} />
    </Switch>
  );
};

export default GuestCardRoute;
