import React, { useEffect, useState } from 'react';
import RestaurantLogo from '../../images/restaurant_logo.svg';
import BonusQrCodeReader from '../../components/Bonus/BonusQrCodeReader';
import '../../style/bonus.scss';
import bonusAPI from '../../middleware/bonusAPI';
import BackBtn from '../../components/Bonus/BackBtn';
import GuestAmount from '../../components/Bonus/GuestAmount';
import AskUseBonus from '../../components/Bonus/AskUseBonus';
import Swal from 'sweetalert2';
import { trim, isArray } from 'lodash';
import { alertError } from '../../Util/Util';
import RewardBonus from '../../components/Bonus/RewardBonus';
import guestCardAPI from '../../middleware/guestCardAPI';
import GuestCardPunch from '../GuestCards/GuestCardPunch';

const Guest = () => {
  const [pageStep, setPageStep] = useState('scanQrcode');
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [guestMember, setGuestMember] = useState();
  const [rewardBonus, setRewardBonus] = useState();
  const [calculateBonus, setCalculateBonus] = useState();
  const [useBonusResult, setUseBonusResult] = useState();
  const [isUseServiceCharge, setIsUseServiceCharge] = useState(false);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [cancelReScanReturnPage, setCancelReScanReturnPage] = useState();
  const [guestCard, setGuestCard] = useState();

  useEffect(() => {
    if (calculateBonus) {
      if (calculateBonus.bonusCalculate === 0) {
        setPageStep('addBonus');
      } else {
        setPageStep('useBonus');
      }
    }
  }, [calculateBonus]);

  useEffect(() => {
    if (useBonusResult) {
      setPageStep('addBonus');
    }
  }, [useBonusResult]);

  useEffect(() => {
    if (rewardBonus) {
      setPageStep('reward');
    }
  }, [rewardBonus]);

  useEffect(() => {
    if (amount) {
      const serviceCharge = Math.round(amount * 0.1);

      setServiceCharge(serviceCharge);
    } else {
      setServiceCharge(0);
    }
  }, [amount]);

  const verifyQrcodeData = (qrcodeData, errorTrigger) => {
    const worker = trim(qrcodeData).startsWith('GC_') ? 'guestCard' : 'bonus';

    // 紅利點數流程
    if (worker === 'bonus') {
      setIsLoading(true);

      return bonusAPI
        .verifyQrcode(qrcodeData)
        .then(
          (res) => {
            if (!guestMember) {
              setGuestMember({ ...res });
            } else if (guestMember.contact !== res.contact) {
              Swal.fire({
                title: '不同會員無法共用',
                text: '如需要使用別的會員，請回首頁重新操作',
              });
            } else {
              const oldProductInfoList = guestMember.productInfoList
                ? [...guestMember.productInfoList]
                : [];
              const newProductInfoList = res.productInfoList
                ? oldProductInfoList.concat(res.productInfoList)
                : oldProductInfoList;

              setGuestMember({
                ...res,
                productInfoList: newProductInfoList,
              });
            }

            setPageStep(
              cancelReScanReturnPage ? cancelReScanReturnPage : 'checkBonus',
            );
          },
          (error) => {
            alertError(error, '無法取的會員資訊', errorTrigger);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }

    // 共享空間
    if (worker === 'guestCard') {
      setIsLoading(true);

      return guestCardAPI
        .getGuestCards({ code: qrcodeData }, 1, 1)
        .then(
          (rs) => {
            if (isArray(rs.cardList) && rs.cardList[0].code) {
              setGuestCard(rs.cardList[0]);
              setPageStep('guestCard');
            } else {
              Swal.fire({
                icon: 'error',
                title: `無法找到此卡資料 [code: ${qrcodeData}]`,
              });
            }
          },
          (error) => {
            alertError(error, '無法取的卡片資訊', errorTrigger);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  const checkBonus = () => {
    if (trim(amount) === '') {
      Swal.fire({
        icon: 'info',
        title: '請填寫消費金額',
      });

      return false;
    }

    setIsLoading(true);

    bonusAPI
      .calculateBonus(guestMember.contact, amount)
      .then(
        (res) => {
          setCalculateBonus(res);
        },
        (error) => {
          alertError(error, '無法取的可折扣的紅利點數資訊');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const addBonus = () => {
    if (trim(amount) === '') {
      Swal.fire({
        icon: 'info',
        title: '請填寫發票金額',
      });

      return false;
    }

    setIsLoading(true);

    const totalAmount = isUseServiceCharge
      ? parseInt(amount, 10) + parseInt(serviceCharge, 10)
      : parseInt(amount, 10);

    bonusAPI
      .accumulatedPoints(guestMember.contact, totalAmount)
      .then(
        (res) => {
          setRewardBonus(res);
        },
        (error) => {
          alertError(error, '增加紅利失敗');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const bonusUse = () => {
    setIsLoading(true);

    bonusAPI
      .redeemBonus(guestMember.contact, amount, calculateBonus.bonusCalculate)
      .then(
        (res) => {
          setAmount(
            parseInt(amount, 10) - parseInt(calculateBonus.bonusCalculate, 10),
          );
          setUseBonusResult({
            use: calculateBonus.bonusCalculate,
            remaining: res,
          });
        },
        (error) => {
          alertError(error, '折扣紅利點數失敗');
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const bonusNoUse = () => {
    setPageStep('addBonus');
  };

  const switchServiceCharge = () => {
    setIsUseServiceCharge(!isUseServiceCharge);
  };

  const reScanQrcode = () => {
    setCancelReScanReturnPage(pageStep);
    setPageStep('scanQrcode');
  };

  const cancelReScanQrcode = () => {
    setPageStep(cancelReScanReturnPage);
  };

  const backAction = () => {
    setGuestMember();
    setAmount('');
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
        <div className="bonus__logo-btn">
          <BackBtn backAction={backAction} />
        </div>
      </div>

      {pageStep === 'scanQrcode' && (
        <BonusQrCodeReader
          verifyQrcodeData={verifyQrcodeData}
          cancelReScanQrcode={guestMember ? cancelReScanQrcode : undefined}
          isLoading={isLoading}
        />
      )}

      {((pageStep === 'checkBonus' && guestMember) ||
        pageStep === 'addBonus') && (
        <GuestAmount
          guestMember={guestMember}
          amount={amount}
          setAmount={setAmount}
          checkBonus={checkBonus}
          addBonus={addBonus}
          isLoading={isLoading}
          pageStep={pageStep}
          useBonusResult={useBonusResult}
          calculateBonus={calculateBonus}
          serviceCharge={serviceCharge}
          isUseServiceCharge={isUseServiceCharge}
          switchServiceCharge={switchServiceCharge}
          reScanQrcode={reScanQrcode}
        />
      )}

      {pageStep === 'useBonus' && (
        <AskUseBonus
          guestMember={guestMember}
          calculateBonus={calculateBonus}
          amount={amount}
          bonusUse={bonusUse}
          bonusNoUse={bonusNoUse}
          isLoading={isLoading}
        />
      )}

      {pageStep === 'reward' && <RewardBonus rewardBonus={rewardBonus} />}

      {pageStep === 'guestCard' && <GuestCardPunch guestCard={guestCard} />}
    </div>
  );
};

export default Guest;
