import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const GuestCardTypeEditModal = (props) => {
  const {
    isOpen,
    close,
    isLoading,
    guestCardType,
    setEditGuestCardType,
    saveGuestCardType,
  } = props;

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      close();
    }
  };

  const handleSave = () => {
    saveGuestCardType();
  };

  const handleChangeValue = (name, value) => {
    if (name !== 'name' && name !== 'description') {
      if (/^[0-9]*$/.test(value) === false) {
        return false;
      }
    }

    setEditGuestCardType({
      ...guestCardType,
      [name]: value,
    });
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullWidth>
      {guestCardType && (
        <>
          <DialogTitle>編輯卡片類型</DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
              spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth={true}
                  label="卡片名稱"
                  value={guestCardType.name || ''}
                  onChange={(e) => {
                    handleChangeValue('name', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="描述"
                  multiline
                  rows={3}
                  fullWidth
                  variant="outlined"
                  disabled={isLoading}
                  value={guestCardType.description || ''}
                  onChange={(e) => {
                    handleChangeValue('description', e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="初始分鐘"
                  value={guestCardType.initialMinutes || ''}
                  onChange={(e) => {
                    handleChangeValue('initialMinutes', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="初始價格"
                  value={guestCardType.initialPrice || ''}
                  onChange={(e) => {
                    handleChangeValue('initialPrice', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="時段分鐘"
                  value={guestCardType.periodMinutes || ''}
                  onChange={(e) => {
                    handleChangeValue('periodMinutes', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="時段價格"
                  value={guestCardType.periodPrice || ''}
                  onChange={(e) => {
                    handleChangeValue('periodPrice', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="優惠分鐘"
                  value={guestCardType.discountMinutes || ''}
                  onChange={(e) => {
                    handleChangeValue('discountMinutes', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth={true}
                  label="最高收費分鐘"
                  value={guestCardType.maxChargeMinutes || ''}
                  onChange={(e) => {
                    handleChangeValue('maxChargeMinutes', e.target.value);
                  }}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Alert severity="error">
              請注意修改後的數值，將直接生效在未結算的卡片上!
            </Alert>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isLoading}>
          關閉
        </Button>
        <Button onClick={handleSave} color="secondary" disabled={isLoading}>
          儲存
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuestCardTypeEditModal;
