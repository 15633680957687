import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import { GuestCardStatusText, LIST_PAGE_COUNT } from '../../data/common';
import { map } from 'lodash';
import guestCardAPI from '../../middleware/guestCardAPI';
import GuestCardTable from '../../components/GuestCard/GuestCardTable';

const GuestCardList = (props) => {
  const { match } = props;
  const [guestCards, setGuestCards] = useState();
  const [guestCardTotal, setGuestCardTotal] = useState();
  const [guestCardTypes, setGuestCardTypes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    status: '',
    code: '',
    typeId: '',
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!guestCardTypes) {
      guestCardAPI.getGuestCardTypes().then(
        (rs) => {
          setGuestCardTypes(rs);
        },
        (error) => {
          alertError(error);
        },
      );
    }
  }, []);

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleChangeSearch = (e) => {
    const changeItem = e.target.name;
    const changeValue = e.target.value;

    setSearch({
      ...search,
      [changeItem]: changeValue,
    });
  };

  const handleSearch = () => {
    setIsLoading(true);

    guestCardAPI
      .getGuestCards(search, LIST_PAGE_COUNT, page)
      .then(
        (rs) => {
          setGuestCards(rs.cardList);
          setGuestCardTotal(rs.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              共享空間卡片列表
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(GuestCardStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading || !guestCardTypes}>
            <InputLabel>卡片類型</InputLabel>
            <Select
              value={search.typeId}
              name="typeId"
              onChange={handleChangeSearch}
              label="卡片類型">
              <MenuItem value="">全部</MenuItem>
              {guestCardTypes &&
                map(guestCardTypes, (guestCardType) => {
                  return (
                    <MenuItem value={guestCardType.id} key={guestCardType.id}>
                      {guestCardType.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <TextField
            label="CODE"
            type="search"
            name="code"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.code}
            onChange={handleChangeSearch}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (page !== 1) {
                  setPage(1);
                } else {
                  handleSearch();
                }
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              } else {
                handleSearch();
              }
            }}>
            搜尋
          </Button>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !guestCards && <TableEmpty message="無共享空間卡片" />}

        {!isLoading && guestCards && (
          <GuestCardTable
            guestCards={guestCards}
            guestCardTotal={guestCardTotal}
            page={page}
            setPage={setPage}
          />
        )}
      </Box>
    </div>
  );
};

export default GuestCardList;
