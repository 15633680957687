import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import _ from 'lodash';
import TablePaginationActions from '../TablePaginationActions';
import { LIST_PAGE_COUNT } from '../../data/common';
import GuestCardHistoryTableRow from './GuestCardHistoryTableRow';

const GuestCardHistoryTable = (props) => {
  const { guestCardHistory, total, openDetail, pageNum, setPageNum } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>卡片</TableCell>
            <TableCell>卡片類型</TableCell>
            <TableCell>cardId</TableCell>
            <TableCell>進場時間</TableCell>
            <TableCell>出場時間</TableCell>
            <TableCell>使用時間</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell>金額</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {_.map(guestCardHistory, (history) => (
            <GuestCardHistoryTableRow
              key={history.id}
              history={history}
              openDetail={openDetail}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={9}
              count={total}
              rowsPerPage={LIST_PAGE_COUNT}
              page={pageNum - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPageNum(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default GuestCardHistoryTable;
