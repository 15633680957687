import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { trim } from 'lodash';
import { GuestCardStatus, GuestCardStatusText } from '../../data/common';
import moment from 'moment';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import { alertError, seconds2time } from '../../Util/Util';
import guestCardAPI from '../../middleware/guestCardAPI';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

/**
 * 由 Bonus/Guest 掃描完後取的資訊使用 [共用掃描]
 */

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  typeName: {
    fontSize: '2rem',
    fontWeight: '600',
    margin: theme.spacing(1, 'auto'),
    color: '#121212',
  },
  guestCardImg: {
    width: '150px',
    maxHeight: '200px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  guestCardStatus: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#eee',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  statusText: {
    fontSize: '1.8rem',
    fontWeight: '600',
  },
  useTime: {
    fontSize: '1rem',
    fontWeight: '400',
    letterSpacing: theme.spacing(0.3),
  },
  useTimeDesc: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  useTimeSec: {
    fontSize: '1.8rem',
    fontWeight: '600',
  },
  desc: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: '#b98f8f',
    margin: theme.spacing(1, 'auto'),
  },
  buttons: {
    margin: theme.spacing(1, 'auto'),
  },
  useResult: {
    backgroundColor: '#ff2d2d',
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  useResultTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  useResultTime: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  billResult: {
    backgroundColor: '#266742',
    color: '#fff',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  billResultRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  billResultRowTitle: {
    flexBasis: '46px',
  },
  billResultTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  billResultTime: {
    fontSize: '1rem',
    fontWeight: '500',
  },
  billResultPrice: {
    fontSize: '2rem',
  },
}));

const GuestCardPunch = (props) => {
  const { guestCard } = props;
  const classes = useStyles();
  const [guestCardState, setGuestCardState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageStep, setPageStep] = useState('info');
  const [punchResult, setPunchResult] = useState();

  useEffect(() => {
    if (
      guestCard &&
      guestCard.cardHistory &&
      trim(guestCard.cardHistory.signInTime) !== '' &&
      trim(guestCard.cardHistory.signOutTime) === ''
    ) {
      const nowTime = moment();
      const signInTime = moment(guestCard.cardHistory.signInTime);

      setGuestCardState({
        status: GuestCardStatus.USE,
        signInTime: signInTime.format('YYYY-MM-DD HH:mm:ss'),
        useTimeSec: nowTime.diff(signInTime, 'seconds'),
      });
    } else {
      setGuestCardState({
        status: GuestCardStatus.UN_USE,
      });
    }
  }, [guestCard]);

  const gusetCardStyle =
    guestCardState && guestCardState.status === GuestCardStatus.USE
      ? {
          backgroundColor: '#ff2d2d',
          color: '#fff',
        }
      : undefined;

  const handleUse = () => {
    setIsLoading(true);

    guestCardAPI
      .punch(guestCard.code)
      .then(
        (rs) => {
          setPunchResult(rs);
          setPageStep('useResult');
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleBill = () => {
    setIsLoading(true);

    guestCardAPI
      .punch(guestCard.code)
      .then(
        (rs) => {
          setPunchResult(rs);
          setPageStep('billResult');
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <img
        className={classes.guestCardImg}
        src={guestCard.cardType.frontImgUrl}
      />

      <div className={classes.typeName}>
        {guestCard.cardType.name}
        <div className={classes.desc} style={{ margin: '0' }}>
          {guestCard.cardType.description}
        </div>
      </div>

      {pageStep === 'info' && (
        <>
          <div className={classes.guestCardStatus} style={gusetCardStyle}>
            <div className={classes.statusText}>
              {guestCardState && GuestCardStatusText[guestCardState.status]}
            </div>

            {guestCardState && guestCardState.status === GuestCardStatus.USE && (
              <div className={classes.useTime}>
                <div className={classes.useTimeSec}>
                  <div className={classes.useTimeDesc}>使用時間</div>
                  {seconds2time(guestCardState.useTimeSec, true)}
                </div>
                <div>{guestCardState.signInTime}</div>
              </div>
            )}
          </div>

          {guestCardState && guestCardState.status === GuestCardStatus.USE && (
            <div className={classes.desc}>計算時間，非即時更新</div>
          )}

          <div className={classes.buttons}>
            {guestCardState && guestCardState.status === GuestCardStatus.USE && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
                startIcon={<ReceiptRoundedIcon />}
                disabled={isLoading}
                onClick={handleBill}>
                結算
              </Button>
            )}

            {guestCardState &&
              guestCardState.status === GuestCardStatus.UN_USE && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  startIcon={<PlayCircleFilledWhiteRoundedIcon />}
                  onClick={handleUse}
                  disabled={isLoading}>
                  使用
                </Button>
              )}
          </div>

          {guestCardState &&
            guestCardState.status === GuestCardStatus.UN_USE &&
            guestCard.cardHistory && (
              <Accordion style={{ marginTop: '24px' }}>
                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                  最新結算記錄
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className={classes.billResult}
                    style={{
                      backgroundColor: '#eee',
                      color: '#616060',
                      width: '100%',
                    }}>
                    <div className={classes.billResultRow}>
                      <div className={classes.billResultRowTitle}>消費金額</div>
                      <div className={classes.billResultPrice}>
                        $ {guestCard.cardHistory.expenseAmount}
                      </div>
                    </div>

                    <div
                      className={classes.billResultRow}
                      style={{ borderTop: '1px solid #fff' }}>
                      <div className={classes.billResultRowTitle}>消費時間</div>
                      <div className={classes.useTimeSec}>
                        {seconds2time(
                          moment(guestCard.cardHistory.signOutTime).diff(
                            guestCard.cardHistory.signInTime,
                            'seconds',
                          ),
                          true,
                        )}
                      </div>
                    </div>

                    <div className={classes.billResultTime}>
                      入場{' '}
                      {moment(guestCard.cardHistory.signInTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )}
                    </div>
                    <div className={classes.billResultTime}>
                      出場{' '}
                      {moment(guestCard.cardHistory.signOutTime).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
        </>
      )}

      {pageStep === 'useResult' && (
        <div className={classes.useResult}>
          <div className={classes.useResultTitle}>已啟用</div>
          <div className={classes.useResultTime}>
            {moment(punchResult.signInTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )}

      {pageStep === 'billResult' && (
        <div className={classes.billResult}>
          <div className={classes.billResultRow}>
            <div className={classes.billResultRowTitle}>消費金額</div>
            <div className={classes.billResultPrice}>$ {punchResult.price}</div>
          </div>

          <div
            className={classes.billResultRow}
            style={{ borderTop: '1px solid #fff' }}>
            <div className={classes.billResultRowTitle}>消費時間</div>
            <div className={classes.useTimeSec}>
              {seconds2time(
                moment(punchResult.signOutTime).diff(
                  punchResult.signInTime,
                  'seconds',
                ),
                true,
              )}
            </div>
          </div>

          <div className={classes.billResultTime}>
            入場 {moment(punchResult.signInTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
          <div className={classes.billResultTime}>
            出場 {moment(punchResult.signOutTime).format('YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestCardPunch;
