import React from 'react';
import RestaurantLogo from '../../images/restaurant_logo.svg';
import '../../style/bonus.scss';
import Button from '@material-ui/core/Button';
import CropFreeIcon from '@material-ui/icons/CropFree';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PermPhoneMsgRoundedIcon from '@material-ui/icons/PermPhoneMsgRounded';
import { useHistory } from 'react-router';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';

const Index = () => {
  const history = useHistory();

  const handleToGuest = () => {
    history.push('/bonus/guest');
  };

  const handleToMarkUpBonus = () => {
    history.push('/bonus/markup');
  };

  const handleToPhoneCode = () => {
    history.push('/PhoneConfirmCodeList');
  };

  const handleToGuestCardInUseList = () => {
    history.push('/GuestCardInUseList');
  };

  return (
    <div className="bonus">
      <div className="bonus__logo">
        <img src={RestaurantLogo} />
      </div>

      <div className="bonus__title">紅利點數 | 共享空間</div>

      <div className="bonus__btns">
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          startIcon={<CropFreeIcon />}
          onClick={handleToGuest}>
          掃描 QRCODE
        </Button>
      </div>

      <div className="bonus__title">紅利點數功能</div>

      <div className="bonus__btns">
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleToMarkUpBonus}>
          補登紅利點數
        </Button>

        <Button
          variant="contained"
          color="default"
          size="large"
          fullWidth
          startIcon={<PermPhoneMsgRoundedIcon />}
          onClick={handleToPhoneCode}>
          顯示有效會員驗證碼
        </Button>
      </div>

      <div className="bonus__title">共享空間功能</div>

      <div className="bonus__btns">
        <Button
          variant="contained"
          color="default"
          size="large"
          fullWidth
          startIcon={<HourglassEmptyRoundedIcon />}
          onClick={handleToGuestCardInUseList}>
          目前已啟用的卡片
        </Button>
      </div>
    </div>
  );
};

export default Index;
