import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import { Box, Paper, Grid, Typography, Divider } from '@material-ui/core';
import TableLoading from '../../components/TableLoading';
import guestCardAPI from '../../middleware/guestCardAPI';
import TableEmpty from '../../components/TableEmpty';
import GuestCardTypeCard from '../../components/GuestCard/GuestCardTypeCard';
import { map, trim } from 'lodash';
import { alertError } from '../../Util/Util';
import Swal from 'sweetalert2';
import GuestCardTypeEditModal from '../../components/GuestCard/GuestCardTypeEditModal';

const GuestCardTypeList = (props) => {
  const { match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [guestCardTypes, setGuestCardTypes] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editGuestCardType, setEditGuestCardType] = useState();

  useEffect(() => {
    getGuestCardTypes();
  }, []);

  const getGuestCardTypes = () => {
    setIsLoading(true);

    guestCardAPI
      .getGuestCardTypes()
      .then((rs) => {
        setGuestCardTypes(rs);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const uploadFrontImg = (guestCardTypeId, file) => {
    setIsLoading(true);

    guestCardAPI
      .uploadFrontImg(guestCardTypeId, file)
      .then((rs) => {
        getGuestCardTypes();
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const uploadBackImg = (guestCardTypeId, file) => {
    setIsLoading(true);

    guestCardAPI
      .uploadBackImg(guestCardTypeId, file)
      .then((rs) => {
        getGuestCardTypes();
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const generateCards = (guestCardTypeId, generateNumber) => {
    setIsLoading(true);

    return guestCardAPI
      .generateTypeCards(guestCardTypeId, generateNumber)
      .then(
        () => {
          getGuestCardTypes();
          Swal.fire({
            title: '建立完成',
            icon: 'success',
          });
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const openEditModal = (guestCardType) => {
    setEditGuestCardType(guestCardType);
    setIsModalOpen(true);
  };

  const saveGuestCardType = () => {
    if (trim(editGuestCardType.name) === '') {
      Swal.fire({
        title: '請輸入卡片名稱',
        icon: 'warning',
      });

      return false;
    }

    if (trim(editGuestCardType.description) === '') {
      Swal.fire({
        title: '請輸入描述',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.initialMinutes) === '' ||
      /^[0-9]*$/.test(editGuestCardType.initialMinutes) === false
    ) {
      Swal.fire({
        title: '請設定初始分鐘，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.initialPrice) === '' ||
      /^[0-9]*$/.test(editGuestCardType.initialPrice) === false
    ) {
      Swal.fire({
        title: '請設定初始價格，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.periodMinutes) === '' ||
      /^[0-9]*$/.test(editGuestCardType.periodMinutes) === false
    ) {
      Swal.fire({
        title: '請設定時段分鐘，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.periodPrice) === '' ||
      /^[0-9]*$/.test(editGuestCardType.periodPrice) === false
    ) {
      Swal.fire({
        title: '請設定時段價格，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.discountMinutes) === '' ||
      /^[0-9]*$/.test(editGuestCardType.discountMinutes) === false
    ) {
      Swal.fire({
        title: '請設定優惠分鐘，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    if (
      trim(editGuestCardType.maxChargeMinutes) === '' ||
      /^[0-9]*$/.test(editGuestCardType.maxChargeMinutes) === false
    ) {
      Swal.fire({
        title: '請設定最高收費分鐘，並只能填寫數字',
        icon: 'warning',
      });

      return false;
    }

    setIsLoading(true);

    guestCardAPI
      .updateGuestCardType(editGuestCardType.id, editGuestCardType)
      .then(
        (rs) => {
          setIsModalOpen(false);
          setEditGuestCardType(undefined);
          getGuestCardTypes();
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              共享空間卡片類型
            </Typography>
          </Grid>
        </Grid>

        <Divider light />

        <Box mt={2} mb={2}>
          {isLoading && <TableLoading />}

          {!isLoading && !guestCardTypes && <TableEmpty message="無卡片類型" />}

          {!isLoading && guestCardTypes && (
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="stretch"
              style={{ gap: '12px' }}>
              {map(guestCardTypes, (guestCardType) => {
                return (
                  <GuestCardTypeCard
                    key={guestCardType.id}
                    guestCardType={guestCardType}
                    uploadFrontImg={uploadFrontImg}
                    uploadBackImg={uploadBackImg}
                    generateCards={generateCards}
                    openEditModal={openEditModal}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      {isModalOpen && editGuestCardType && (
        <GuestCardTypeEditModal
          guestCardType={editGuestCardType}
          setEditGuestCardType={setEditGuestCardType}
          isOpen={isModalOpen}
          close={() => {
            setIsModalOpen(false);
          }}
          isLoading={isLoading}
          saveGuestCardType={saveGuestCardType}
        />
      )}
    </div>
  );
};

export default GuestCardTypeList;
