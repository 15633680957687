import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { trim } from 'lodash';
import { Button } from '@material-ui/core';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '160px',
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    maxHeight: '180px',
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  uploadBtn: {
    margin: theme.spacing(1, 'auto'),
  },
  emptyImg: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    color: '#a9a9a9',
  },
}));

const ImgUpload = (props) => {
  const { imgUrl, emptyText, uploadImage, isLoading } = props;
  const classes = useStyles();

  const handleFileUpload = (e) => {
    uploadImage(e.target.files[0]);
  };

  return (
    <div className={classes.root}>
      {trim(imgUrl) !== '' && <img className={classes.img} src={imgUrl} />}

      {trim(imgUrl) === '' && (
        <div className={classes.emptyImg}>{emptyText}</div>
      )}

      <Button
        variant="contained"
        color="primary"
        startIcon={<PublishRoundedIcon />}
        size="small"
        className={classes.uploadBtn}
        disabled={isLoading}
        component="label">
        點擊上傳圖片
        <input
          style={{ display: 'none' }}
          accept="image/*"
          type="file"
          onChange={handleFileUpload}
          disabled={isLoading}
        />
      </Button>
    </div>
  );
};

export default ImgUpload;
