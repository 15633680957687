import React, { useEffect, useState } from 'react';
import { Button, TableCell, TableRow, Chip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
  BonusAuditStatus,
  BonusAuditStatusText,
  BonusSaleTypes,
  BonusStatus,
  BonusStatusText,
} from '../../data/common';
import { formatNumberComma, seconds2time } from '../../Util/Util';
import moment from 'moment';
import VerifiedUserRoundedIcon from '@material-ui/icons/VerifiedUserRounded';
import { trim } from 'lodash';
import TimerRoundedIcon from '@material-ui/icons/TimerRounded';
import ReceiptRoundedIcon from '@material-ui/icons/ReceiptRounded';

const GuestCardHistoryTableRow = (props) => {
  const { history, openDetail } = props;

  const handleOpenDetail = () => {
    openDetail(history);
  };

  return (
    <TableRow>
      <TableCell component="th">
        <img
          src={history.cardInfo.cardType.frontImgUrl}
          style={{ width: '60px' }}
        />
      </TableCell>
      <TableCell component="th">{history.cardInfo.cardType.name}</TableCell>
      <TableCell>{history.cardId}</TableCell>
      <TableCell>{history.signInTime}</TableCell>
      <TableCell>{history.signOutTime}</TableCell>
      <TableCell>{seconds2time(history.useTimeSec, true)}</TableCell>
      <TableCell>
        <Chip
          color={history.historyStatus === 'bill' ? 'secondary' : 'primary'}
          icon={
            history.historyStatus === 'bill' ? (
              <ReceiptRoundedIcon />
            ) : (
              <TimerRoundedIcon />
            )
          }
          label={history.historyStatus === 'bill' ? '已結帳' : '使用中'}
        />
      </TableCell>
      <TableCell>
        {history.historyStatus === 'bill' && (
          <span>$ {formatNumberComma(history.expenseAmount)}</span>
        )}
      </TableCell>
      <TableCell align="right">
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<AssignmentIcon />}
          style={{ marginRight: '12px' }}
          onClick={handleOpenDetail}>
          檢視
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default GuestCardHistoryTableRow;
