import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { LIST_PAGE_COUNT } from '../../data/common';
import TablePaginationActions from '../TablePaginationActions';
import { map } from 'lodash';
import GuestCardTableRow from './GuestCardTableRow';

const GuestCardTable = (props) => {
  const { guestCards, guestCardTotal, page, setPage } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>圖片</TableCell>
            <TableCell>卡片類型</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell>啟用時間</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(guestCards, (guestCard) => (
            <GuestCardTableRow key={guestCard.code} guestCard={guestCard} />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={5}
              count={guestCardTotal}
              rowsPerPage={LIST_PAGE_COUNT}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default GuestCardTable;
