const RouteMap = {
  '': '首頁',
  MemberManagement: '會員管理',
  BougthRecord: '購買紀錄',

  SetExam: '編卷管理',

  ProductList: '商品科目管理',

  SaleExam: '售卷管理',
  ExamList: '售卷列表',
  SuitExamList: '套卷列表',
  ExamType: '考卷類別管理',
  SubjectType: '考卷科目管理',

  Teacher: '師資管理',

  Secret: '課程管理',
  SecretList: '編輯課程',
  AddSecret: '編輯課程',
  EditSecret: '編輯課程',

  PeiyouSetting: '培優補差設定',

  Advertising: '廣告管理',
  PopularList: '熱門精選列表',
  Popular: '熱門精選',
  HomeCarousel: '首頁輪播',
  QuestionBank: '題庫商店廣告',

  PromoList: '活動管理',
  OrderList: '訂單管理',
  Video: '影片管理',
  VideoList: '影片列表',
  Handout: '講義管理',
  HandoutList: '講義列表',

  Create: '建立',
  Edit: '編輯',

  Bonus: '紅利點數',
  guest: '顧客消費',
  markup: '補登紅利',
  BonusList: '紅利管理列表',
  AuditList: '稽核列表',

  PhoneConfirmCodeList: '手機驗證碼列表',

  BonusProducts: '紅利商城',
  BonusProductList: '紅利商品列表',
  UserBonusProductList: '兌換列表',
  RecommendProductList: '推薦商品',

  GuestCards: '共享空間',
  GuestCardTypeList: '卡片類型',
  GuestCardList: '卡片列表',
  GuestCardInUseList: '共享空間',
  GuestCardHistory: '使用記錄',
};

export default RouteMap;
