import instance from './API';
import { trim } from 'lodash';

const guestCardAPI = {
  punch: (code) => {
    return instance.post('/GuestCard/Punch', null, {
      params: {
        code: code,
      },
    });
  },
  getGuestCardTypes: () => {
    return instance.get('/GuestCardType');
  },
  updateGuestCardType: (typeId, guestCardType) => {
    const updateParams = new URLSearchParams();

    updateParams.append('Name', guestCardType.name);
    updateParams.append('Description', guestCardType.description);
    updateParams.append('PeriodMinutes', guestCardType.periodMinutes);
    updateParams.append('PeriodPrice', guestCardType.periodPrice);
    updateParams.append('InitialMinutes', guestCardType.initialMinutes);
    updateParams.append('InitialPrice', guestCardType.initialPrice);
    updateParams.append('DiscountMinutes', guestCardType.discountMinutes);
    updateParams.append('MaxChargeMinutes', guestCardType.maxChargeMinutes);

    return instance.put(`/GuestCardType/${typeId}`, updateParams);
  },
  uploadFrontImg: (typeId, file) => {
    const uploadFormData = new FormData();

    uploadFormData.append('File', file);

    return instance.post('/GuestCardType/FrontImg', uploadFormData, {
      params: {
        typeId: typeId,
      },
    });
  },
  uploadBackImg: (typeId, file) => {
    const uploadFormData = new FormData();

    uploadFormData.append('File', file);

    return instance.post('/GuestCardType/BackImg', uploadFormData, {
      params: {
        typeId: typeId,
      },
    });
  },
  getGuestCards: (search, count, page) => {
    return instance.get('/GuestCard', {
      params: {
        page: page,
        count: count,
        status: trim(search.status) === '' ? undefined : search.status,
        typeId: trim(search.typeId) === '' ? undefined : search.typeId,
        code: trim(search.code) === '' ? undefined : search.code,
      },
    });
  },
  generateTypeCards: (typeId, count) => {
    return instance.post('/GuestCard', null, {
      params: {
        count: count,
        typeId: typeId,
      },
    });
  },
  getGuestCardHistory: (search, count, page) => {
    return instance.get('/GuestCardHistory', {
      params: {
        page: page,
        count: count,
        startAt: trim(search.startAt) !== '' ? search.startAt : undefined,
        endAt: trim(search.endAt) !== '' ? search.endAt : undefined,
        cardId: trim(search.cardId) !== '' ? search.cardId : undefined,
        typeId: trim(search.typeId) !== '' ? search.typeId : undefined,
      },
    });
  },
};

export default guestCardAPI;
